import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import PageIllustration from './PageIllustration';
import { Trash2, Users } from 'lucide-react';

function TargetAudiencePage() {
  const [targetAudience, setTargetAudience] = useState(null);
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTargetAudience = async () => {
      try {
        const response = await axios.get(`/api/target-audience/${id}`);
        setTargetAudience(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching target audience:', error);
        setLoading(false);
      }
    };

    fetchTargetAudience();
  }, [id]);

  const handleDelete = async () => {
    if (window.confirm('このターゲット設定を削除してもよろしいですか？')) {
      setDeleting(true);
      try {
        await axios.delete(`/api/target-audience/${id}`);
        setTargetAudience(null);
      } catch (error) {
        console.error('Error deleting target audience:', error);
        alert('削除に失敗しました。もう一度お試しください。');
      } finally {
        setDeleting(false);
      }
    }
  };

  if (loading) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <div className="h-8 w-8 animate-spin rounded-full border-2 border-gray-800 border-t-transparent"></div>
      </div>
    );
  }

  return (
    <section className="relative min-h-screen">
      <PageIllustration />
      <div className="relative z-10 mx-auto max-w-6xl px-4 sm:px-6">
        <div className="pb-12 pt-32 md:pb-20 md:pt-40">
          <div className="mb-8 text-center">
            <h1 className="mb-4 text-3xl font-bold tracking-[-0.037em] text-gray-800 md:text-4xl lg:text-5xl">
              ターゲット設定
            </h1>
          </div>

          {targetAudience ? (
            <div className="overflow-hidden rounded-2xl bg-white/90 shadow-lg backdrop-blur-sm">
              <div className="relative p-6">
                {/* Delete button */}
                <button
                  onClick={handleDelete}
                  disabled={deleting}
                  className="absolute right-6 top-6 rounded-xl p-2 text-red-500 transition-colors hover:bg-red-50 disabled:opacity-50"
                >
                  <Trash2 size={20} />
                </button>

                {/* Analysis content */}
                <div className="mt-4 whitespace-pre-wrap text-gray-600">
                  {targetAudience.analysis}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center gap-6">
              <div className="rounded-full bg-gray-100 p-6">
                <Users size={48} className="text-gray-400" />
              </div>
              <div className="text-center">
                <h2 className="mb-2 text-xl font-semibold text-gray-800">
                  ターゲット設定がありません
                </h2>
                <p className="mb-6 text-gray-600">
                  この記事のターゲットオーディエンスをまだ設定していません。
                </p>
                <button
                  onClick={() => navigate(`/create-target-audience/${id}`)}
                  className="inline-flex items-center rounded-xl bg-gray-800 px-6 py-3 text-sm font-medium text-white shadow-sm transition-all hover:bg-gray-700"
                >
                  <Users size={20} className="mr-2" />
                  ターゲットを設定する
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default TargetAudiencePage;