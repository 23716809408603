import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import PageIllustration from './PageIllustration';

function OutlineView() {
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`/api/articles/${id}`);
        console.log('Article data:', response.data);
        setArticle(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching article:', error);
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  if (loading) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <div className="h-8 w-8 animate-spin rounded-full border-2 border-gray-800 border-t-transparent"></div>
      </div>
    );
  }

  if (!article) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <p className="text-gray-600">記事が見つかりませんでした。</p>
      </div>
    );
  }

  const isQuickArticle = !article.intro && !article.faq;

  return (
    <section className="relative min-h-screen">
      <PageIllustration />
      <div className="relative z-10 mx-auto max-w-4xl px-4 sm:px-6">
        {/* Page Title */}
        <div className="pt-24 text-center md:pt-32">
          <h1 className="mb-4 text-2xl font-bold text-gray-800 md:text-3xl">
            アウトライン
          </h1>
        </div>
        
        <div className="pb-12 pt-8 md:pb-20">
          <article className="overflow-hidden rounded-2xl bg-white/90 p-6 shadow-lg backdrop-blur-sm md:p-8">
            {/* Featured Image */}
            {article.featuredImage && (
              <div className="relative mb-8 overflow-hidden rounded-xl">
                {imageLoading && (
                  <div className="h-[400px] w-full animate-pulse bg-gray-200"></div>
                )}
                <img
                  src={article.featuredImage}
                  alt={article.h1Title}
                  className="h-auto w-full"
                  style={{
                    marginBottom: imageLoading ? '-400px' : '0'
                  }}
                  onLoad={() => setImageLoading(false)}
                  onError={() => setImageLoading(false)}
                />
              </div>
            )}

            {/* Article Title */}
            <h1 className="mb-6 text-3xl font-bold tracking-[-0.037em] text-gray-800 md:text-4xl">
              <span className="mr-2 text-gray-500">H1:</span>
              {article.h1Title}
            </h1>

            {/* Intro */}
            {!isQuickArticle && article.intro && (
              <div className="mb-8 text-lg leading-relaxed text-gray-600">
                {article.intro}
              </div>
            )}

            {/* Sections */}
            {article.sections && article.sections.map((section, index) => (
              <div key={index} className="mb-8">
                <h2 className="mb-4 text-2xl font-bold text-gray-800">
                  <span className="mr-2 text-gray-500">H2:</span>
                  {section.h2Title}
                </h2>
                {section.h3Sections && section.h3Sections.map((h3Section, h3Index) => (
                  <div key={h3Index} className="mb-6">
                    <h3 className="mb-2 text-xl font-semibold text-gray-800">
                      <span className="mr-2 text-gray-500">H3:</span>
                      {h3Section.h3Title}
                    </h3>
                    <div className="text-gray-600">
                      {h3Section.notes}
                    </div>
                  </div>
                ))}
              </div>
            ))}

            {/* FAQ Section */}
            {!isQuickArticle && article.faq && (
              <>
                <div className="my-8 h-px bg-gray-200"></div>
                <div className="mb-8">
                  <h2 className="mb-6 text-2xl font-bold text-gray-800">
                    <span className="mr-2 text-gray-500">H2:</span>
                    よくある質問
                  </h2>
                  <div className="space-y-6">
                    {article.faq.map((faq, index) => (
                      <div key={index} className="rounded-xl bg-gray-50 p-6">
                        <h3 className="mb-3 text-xl font-semibold text-gray-800">
                          <span className="mr-2 text-gray-500">H3:</span>
                          {faq.question}
                        </h3>
                        <div className="text-gray-600">
                          {faq.answer}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </article>
        </div>
      </div>
    </section>
  );
}

export default OutlineView;