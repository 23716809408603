import React from 'react';

const StyledArticlePage = ({ content }) => {
  return (
    <div className="prose max-w-none">
      <style>
        {`
          /* Base text styling */
          .prose {
            color: rgb(55 65 81);
            line-height: 1.75;
          }

          /* Headings with left border and background */
          .wp-block-heading {
            font-weight: 600;
            line-height: 1.4;
            margin: 2rem 0 1rem;
            padding: 0.75rem 1rem;
            border-radius: 0.75rem;
            background: rgba(255, 255, 255, 0.9);
            border-left: 4px solid rgb(107 114 128);
          }

          /* H2 specific styling */
          h2.wp-block-heading {
            font-size: 1.5rem;
            border-left-color: rgb(75 85 99);
          }

          /* H3 specific styling */
          h3.wp-block-heading {
            font-size: 1.25rem;
            border-left-color: rgb(107 114 128);
            background: rgb(249 250 251);
          }

          /* Containers with subtle backgrounds */
          .wp-block-group {
            padding: 1.5rem;
            border-radius: 0.75rem;
            margin: 1.5rem 0;
            background: rgb(249 250 251);
            border: 1px solid rgb(229 231 235);
          }

          /* Special containers */
          .is-style-big_icon_good {
            background: rgb(243 244 246);
            border-left: 4px solid rgb(34 197 94);
          }

          .is-style-big_icon_point {
            background: rgb(254 242 242);
            border-left: 4px solid rgb(239 68 68);
          }

          /* Lists with proper spacing */
          .wp-block-list {
            margin: 1.5rem 0;
            padding-left: 1.25rem;
          }

          .wp-block-list li {
            margin-bottom: 0.5rem;
          }

          /* Check lists */
          .wp-block-list.is-style-check_list {
            list-style: none;
            padding-left: 1.5rem;
          }

          .wp-block-list.is-style-check_list li {
            position: relative;
            padding-left: 1.75rem;
          }

          .wp-block-list.is-style-check_list li::before {
            content: '✓';
            position: absolute;
            left: 0;
            color: rgb(34 197 94);
          }

          /* Numbered lists */
          .wp-block-list.is-style-num_circle {
            list-style: none;
            counter-reset: item;
          }

          .wp-block-list.is-style-num_circle li {
            position: relative;
            padding-left: 2.5rem;
            margin-bottom: 1rem;
          }

          .wp-block-list.is-style-num_circle li::before {
            counter-increment: item;
            content: counter(item);
            position: absolute;
            left: 0;
            background: rgb(59 130 246);
            color: white;
            width: 1.75rem;
            height: 1.75rem;
            border-radius: 9999px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.875rem;
          }

          /* Accordion styling */
          .swell-block-accordion__item {
            border: 1px solid rgb(229 231 235);
            border-radius: 0.75rem;
            margin-bottom: 1rem;
            background: white;
          }

          .swell-block-accordion__title {
            padding: 1rem 1.5rem;
            cursor: pointer;
            font-weight: 600;
          }

          .swell-block-accordion__body {
            padding: 1.5rem;
            border-top: 1px solid rgb(229 231 235);
            background: rgb(249 250 251);
          }

          /* FAQ styling */
          .swell-block-faq__item {
            background: white;
            border-radius: 0.75rem;
            padding: 1.5rem;
            margin-bottom: 1rem;
            border: 1px solid rgb(229 231 235);
          }

          .faq_q {
            font-weight: 600;
            font-size: 1.125rem;
            color: rgb(17 24 39);
            margin-bottom: 1rem;
            padding-left: 2rem;
            position: relative;
          }

          .faq_q::before {
            content: 'Q.';
            position: absolute;
            left: 0;
            color: rgb(59 130 246);
            font-weight: 700;
          }

          .faq_a {
            padding-left: 2rem;
            position: relative;
          }

          .faq_a::before {
            content: 'A.';
            position: absolute;
            left: 0;
            color: rgb(239 68 68);
            font-weight: 700;
          }

          /* Tables */
          .wp-block-table {
            margin: 1.5rem 0;
            overflow-x: auto;
          }

          .wp-block-table table {
            width: 100%;
            border-collapse: collapse;
            background: white;
            border-radius: 0.75rem;
            overflow: hidden;
          }

          .wp-block-table th,
          .wp-block-table td {
            padding: 0.75rem 1rem;
            border: 1px solid rgb(229 231 235);
          }

          .wp-block-table th {
            background: rgb(243 244 246);
            font-weight: 600;
          }

          /* Text highlights */
          .swl-marker.mark_orange {
            background: linear-gradient(transparent 60%, rgba(251, 146, 60, 0.3) 40%);
            padding: 0 0.25rem;
          }

          /* Buttons */
          .swell-block-button {
            display: inline-block;
            margin: 1rem 0;
          }

          .swell-block-button__link {
            display: inline-block;
            padding: 0.75rem 1.5rem;
            border-radius: 0.75rem;
            font-weight: 600;
            text-decoration: none;
            transition: all 0.2s;
          }

          .green_ .swell-block-button__link {
            background: rgb(34 197 94);
            color: white;
          }

          .green_ .swell-block-button__link:hover {
            background: rgb(22 163 74);
          }

          .-size-l .swell-block-button__link {
            padding: 1rem 2rem;
            font-size: 1.125rem;
          }
        `}
      </style>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

export default StyledArticlePage;