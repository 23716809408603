import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import PageIllustration from './PageIllustration';
import { Plus, Trash2, Save } from 'lucide-react';

function CreateTargetAudience() {
  const [article, setArticle] = useState(null);
  const [conditions, setConditions] = useState(['']);
  const [loading, setLoading] = useState(true);
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`/api/articles/${id}`);
        setArticle(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching article:', error);
        setError('記事の取得に失敗しました。もう一度お試しください。');
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  const handleConditionChange = (index, value) => {
    const newConditions = [...conditions];
    newConditions[index] = value;
    setConditions(newConditions);
  };

  const addCondition = () => {
    setConditions([...conditions, '']);
  };

  const removeCondition = (index) => {
    const newConditions = conditions.filter((_, i) => i !== index);
    setConditions(newConditions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCreating(true);
    setError(null);
    try {
      await axios.post('/api/create-target-audience', {
        articleId: id,
        conditions: conditions.filter(condition => condition.trim() !== '').join('\n')
      });
      navigate(`/target-audience/${id}`);
    } catch (error) {
      console.error('Error creating target audience:', error);
      setError('ターゲットオーディエンスの作成に失敗しました。もう一度お試しください。');
    } finally {
      setCreating(false);
    }
  };

  if (loading) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <div className="h-8 w-8 animate-spin rounded-full border-2 border-gray-800 border-t-transparent"></div>
      </div>
    );
  }

  if (!article) return null;

  return (
    <section className="relative min-h-screen">
      <PageIllustration />
      <div className="relative z-10 mx-auto max-w-6xl px-4 sm:px-6">
        <div className="pb-12 pt-32 md:pb-20 md:pt-40">
          <div className="mb-8 text-center">
            <h1 className="mb-4 text-3xl font-bold tracking-[-0.037em] text-gray-800 md:text-4xl lg:text-5xl">
              ターゲット設定
            </h1>
            <p className="text-xl text-gray-600">
              {article.h1Title}
            </p>
          </div>

          {error && (
            <div className="mb-6 rounded-2xl bg-red-50 p-4 text-red-600">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            {conditions.map((condition, index) => (
              <div
                key={index}
                className="overflow-hidden rounded-2xl bg-white/90 shadow-lg backdrop-blur-sm"
              >
                <div className="flex items-center gap-4 p-6">
                  <div className="flex-1">
                    <input
                      type="text"
                      value={condition}
                      onChange={(e) => handleConditionChange(index, e.target.value)}
                      placeholder={`条件 ${index + 1}`}
                      className="w-full rounded-xl border-2 border-gray-200 px-4 py-3 text-gray-800 placeholder-gray-400 focus:border-blue-500 focus:outline-none"
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => removeCondition(index)}
                    className="rounded-xl p-3 text-red-500 transition-colors hover:bg-red-50"
                  >
                    <Trash2 size={20} />
                  </button>
                </div>
              </div>
            ))}

            <div className="flex justify-center gap-4">
              <button
                type="button"
                onClick={addCondition}
                className="inline-flex items-center rounded-xl bg-white px-6 py-3 text-sm font-medium text-gray-800 shadow-sm transition-all hover:bg-gray-50"
              >
                <Plus size={20} className="mr-2" />
                条件を追加
              </button>

              <button
                type="submit"
                disabled={creating}
                className="inline-flex items-center rounded-xl bg-gray-800 px-6 py-3 text-sm font-medium text-white shadow-sm transition-all hover:bg-gray-700 disabled:opacity-50"
              >
                {creating ? (
                  <div className="h-5 w-5 animate-spin rounded-full border-2 border-white border-t-transparent" />
                ) : (
                  <>
                    <Save size={20} className="mr-2" />
                    保存する
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default CreateTargetAudience;